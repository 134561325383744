import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

const StyledPortfolio = styled.div`
  min-height: 100vh;
  padding-top: 150px;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 100px;
  .heading {
    font-size: 1.5vw;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.7vw;
    .grid-item {
      cursor: pointer;
      position: relative;
      .image {
        transition: filter 0.1s;
      }
      .name {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0;
        transition: opacity 0.1s;
        color: white;
        font-size: 1.5vw;
        font-family: "Akzidenz Grotesk Extended";
        left: 50%;
        text-align: center;
      }
      .category {
        position: absolute;
        bottom: 1.5vw;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.1s;
        color: white;
        font-size: 1.2vw;
        font-family: "Akzidenz Grotesk Extended";
        left: 50%;
        text-align: center;
      }
    }
    .grid-item:hover {
      .image {
        filter: brightness(30%);
        transition: filter 0.2s;
      }
      .name {
        opacity: 100;
        transition: opacity 0.3s;
      }
      .category {
        opacity: 100;
        transition: opacity 0.3s;
      }
    }
  }
`

const Portfolio = () => {
  const data = useStaticQuery(graphql`
    query {
      albens: file(relativePath: { eq: "all-projects/all-albens.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aloevera: file(relativePath: { eq: "all-projects/all-aloevera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cling: file(relativePath: { eq: "all-projects/all-cling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      esenses: file(relativePath: { eq: "all-projects/all-esenses.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eternalplus: file(
        relativePath: { eq: "all-projects/all-eternalplus.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fem: file(relativePath: { eq: "all-projects/all-fem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hiroKids: file(relativePath: { eq: "all-projects/all-hiro-kids.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      krfty: file(relativePath: { eq: "all-projects/all-krfty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      micellar: file(relativePath: { eq: "all-projects/all-micellar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mighty: file(relativePath: { eq: "all-projects/all-mighty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      monfina: file(relativePath: { eq: "all-projects/all-monfina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nuvo: file(relativePath: { eq: "all-projects/all-nuvo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s1mpl: file(relativePath: { eq: "all-projects/all-s1mpl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      studioTropik: file(
        relativePath: { eq: "all-projects/all-studio-tropik.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sweety: file(relativePath: { eq: "all-projects/all-sweety.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thisIsApril: file(
        relativePath: { eq: "all-projects/all-this-is-april.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vigo: file(relativePath: { eq: "all-projects/all-vigo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledPortfolio>
      <h1 className="heading">ALL PROJECTS</h1>
      <div className="grid-container">
        <Link to="/portfolio/albens">
          <div className="grid-item">
            <Img fluid={data.albens.childImageSharp.fluid} className="image" />
            <div className="name">ALBENS CIDER</div>
            <div className="category">Food & Beverage</div>
          </div>
        </Link>
        <Link to="/portfolio/hiro-kids">
          <div className="grid-item">
            <Img
              fluid={data.hiroKids.childImageSharp.fluid}
              className="image"
            />
            <div className="name">HIRO KIDS</div>
            <div className="category">Food & Beverage</div>
          </div>
        </Link>
        <Link to="/portfolio/eternal-plus">
          <div className="grid-item">
            <Img
              fluid={data.eternalplus.childImageSharp.fluid}
              className="image"
            />
            <div className="name">E+ BOTTLE</div>
            <div className="category">Food & Beverage</div>
          </div>
        </Link>
        <Link to="/portfolio/vigo">
          <div className="grid-item">
            <Img fluid={data.vigo.childImageSharp.fluid} className="image" />
            <div className="name">VIGO</div>
            <div className="category">Food & Beverage</div>
          </div>
        </Link>
        <Link to="/portfolio/studio-tropik">
          <div className="grid-item">
            <Img
              fluid={data.studioTropik.childImageSharp.fluid}
              className="image"
            />
            <div className="name">STUDIO TROPIK</div>
            <div className="category">Pharmacy</div>
          </div>
        </Link>
        <Link to="/portfolio/cling">
          <div className="grid-item">
            <Img fluid={data.cling.childImageSharp.fluid} className="image" />
            <div className="name">CLING</div>
            <div className="category">Home & Garden</div>
          </div>
        </Link>
        <Link to="/portfolio/micellar">
          <div className="grid-item">
            <Img
              fluid={data.micellar.childImageSharp.fluid}
              className="image"
            />
            <div className="name">MICELLAR</div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/aloevera">
          <div className="grid-item">
            <Img
              fluid={data.aloevera.childImageSharp.fluid}
              className="image"
            />
            <div className="name">
              ALOE VERA + <br />
              CUCUMBER
            </div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/monfina">
          <div className="grid-item">
            <Img fluid={data.monfina.childImageSharp.fluid} className="image" />
            <div className="name">MONFINA</div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/esenses">
          <div className="grid-item">
            <Img fluid={data.esenses.childImageSharp.fluid} className="image" />
            <div className="name">ESENSES</div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/fem">
          <div className="grid-item">
            <Img fluid={data.fem.childImageSharp.fluid} className="image" />
            <div className="name">FEM</div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/nuvo">
          <div className="grid-item">
            <Img fluid={data.nuvo.childImageSharp.fluid} className="image" />
            <div className="name">NUVO</div>
            <div className="category">Health & Beauty</div>
          </div>
        </Link>
        <Link to="/portfolio/mighty">
          <div className="grid-item">
            <Img fluid={data.mighty.childImageSharp.fluid} className="image" />
            <div className="name">MIGHTY</div>
            <div className="category">Others</div>
          </div>
        </Link>
        <Link to="/portfolio/krfty">
          <div className="grid-item">
            <Img fluid={data.krfty.childImageSharp.fluid} className="image" />
            <div className="name">KRFTY</div>
            <div className="category">Others</div>
          </div>
        </Link>
        <Link to="/portfolio/s1mpl">
          <div className="grid-item">
            <Img fluid={data.s1mpl.childImageSharp.fluid} className="image" />
            <div className="name">S1MPL</div>
            <div className="category">Others</div>
          </div>
        </Link>
        <Link to="/portfolio/sweety">
          <div className="grid-item">
            <Img fluid={data.sweety.childImageSharp.fluid} className="image" />
            <div className="name">SWEETY</div>
            <div className="category">Others</div>
          </div>
        </Link>
        <Link to="/portfolio/this-is-april">
          <div className="grid-item">
            <Img
              fluid={data.thisIsApril.childImageSharp.fluid}
              className="image"
            />
            <div className="name">THIS IS APRIL</div>
            <div className="category">Others</div>
          </div>
        </Link>
      </div>
    </StyledPortfolio>
  )
}

export default Portfolio
