import React from "react"
import { useMediaQuery } from "react-responsive"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Portfolio from "../../components/Portfolio"
import PortfolioMobile from "../../components/PortfolioMobile"

const PortfolioPage = ({ location }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 900px)",
  })
  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  })
  return (
    <Layout location={location}>
      <SEO title="Home" keywords={[`BMR`, `labels`]} />
      {isDesktop && <Portfolio />}
      {isMobile && <PortfolioMobile />}
    </Layout>
  )
}

export default PortfolioPage
