import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import AlbensModal from "./AlbensModal"
import HiroKidsModal from "./HiroKidsModal"
import EternalplusModal from "./EternalplusModal"
import VigoModal from "./VigoModal"
import StudioTropikModal from "./StudioTropikModal"
import ClingModal from "./ClingModal"
import MicellarModal from "./MicellarModal"
import AloeveraModal from "./AloeveraModal"
import MonfinaModal from "./MonfinaModal"
import EsensesModal from "./EsensesModal"
import NuvoModal from "./NuvoModal"
import MightyModal from "./MightyModal"
import KrftyModal from "./KrftyModal"
import S1mplModal from "./S1mplModal"
import SweetyModal from "./SweetyModal"
import ThisIsAprilModal from "./ThisIsAprilModal"

const StyledPortfolioMobile = styled.div`
  padding-top: 80px;
  font-family: "Akzidenz Grotesk Extended";
  .heading {
    position: relative;
    margin-left: 80px;
    font-family: "Akzidenz Grotesk Extended";
    color: #888888;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    .line {
      position: absolute;
      height: 1px;
      width: 80px;
      background-color: #888888;
      right: 100%;
      top: 0.65rem;
      transform: translateX(-10px);
    }
  }
  .all-projects-wrapper {
    position: relative;
    .dropdown {
      position: absolute;
      transform: translateY(15px);
      color: white;
      background-color: #4f4f4f;
      z-index: 2;
      padding: 1rem;
      padding-bottom: 2rem;
      p {
        border-bottom: 1px solid white;
        padding: 0.5rem 0;
        margin: 0;
      }
      &.hide {
        display: none;
      }
    }
    .arrow-up {
      width: 0;
      height: 0;
      transform: translateY(5px) translateX(10px);
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #4f4f4f;
      position: absolute;
      &.hide {
        display: none;
      }
    }
  }
  .modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    z-index: 12;
  }
`

const StyledItem = styled.div`
  margin-bottom: 2rem;
  .content {
    margin-left: 2rem;
    .heading {
      margin-left: 0;
      font-family: "Akzidenz Grotesk Extended";
      font-weight: 400;
      color: black;
      font-size: 1rem;
      margin-bottom: 0.9rem;
      margin-top: 1rem;
    }
    .info {
      display: flex;
      justify-content: space-between;
      font-size: 0.7rem;
      margin-right: 1rem;
      .detail {
        line-height: 1rem;
        color: #555555;
        .top {
          font-weight: 600;
        }
      }
    }
  }
`

const Item = props => {
  return (
    <StyledItem>
      <div
        onClick={() => {
          console.log("image is clicked")
          props.openModal(props.item.image)
        }}
      >
        <Img
          fluid={props.data[props.image].childImageSharp.fluid}
          className="image"
        />
      </div>
      <div className="content">
        <h2 className="heading">{props.heading}</h2>
        <div className="info">
          <div className="detail">
            <div className="top">Role</div>
            <div className="bottom">{props.role}</div>
          </div>
          <div className="detail">
            <div className="top">Client</div>
            <div className="bottom">{props.client}</div>
          </div>
          <div className="detail">
            <div className="top">Categories</div>
            <div className="bottom">{props.categories}</div>
          </div>
        </div>
      </div>
    </StyledItem>
  )
}

const PortfolioMobile = () => {
  const [visible, setVisible] = useState(false)
  const dropdownRef = useRef(null)
  const [modal, setModal] = useState(false)
  const [albensModal, setAlbensModal] = useState(false)
  const [hiroKidsModal, setHiroKidsModal] = useState(false)
  const [eternalplusModal, setEternalplusModal] = useState(false)
  const [vigoModal, setVigoModal] = useState(false)
  const [studioTropikModal, setStudioTropikModal] = useState(false)
  const [clingModal, setClingModal] = useState(false)
  const [micellarModal, setMicellarModal] = useState(false)
  const [aloeveraModal, setAloeveraModal] = useState(false)
  const [monfinaModal, setMonfinaModal] = useState(false)
  const [esensesModal, setEsensesModal] = useState(false)
  const [nuvoModal, setNuvoModal] = useState(false)
  const [mightyModal, setMightyModal] = useState(false)
  const [krftyModal, setKrftyModal] = useState(false)
  const [s1mplModal, setS1mplModal] = useState(false)
  const [sweetyModal, setSweetyModal] = useState(false)
  const [thisIsAprilModal, setThisIsAprilModal] = useState(false)

  useEffect(() => {
    const clickListener = e => {
      if (visible) {
        if (dropdownRef.current.contains(e.target)) {
          // Clicked in box
          console.log("asd")
        } else {
          console.log("dropdown is not clicked")
          setVisible(false)
        }
      }
    }
    window.addEventListener("click", clickListener)
    return () => window.removeEventListener("click", clickListener)
  }, [visible, modal])

  const data = useStaticQuery(graphql`
    query {
      albens: file(relativePath: { eq: "all-projects/all-albens.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aloevera: file(relativePath: { eq: "all-projects/all-aloevera.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cling: file(relativePath: { eq: "all-projects/all-cling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      esenses: file(relativePath: { eq: "all-projects/all-esenses.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eternalplus: file(
        relativePath: { eq: "all-projects/all-eternalplus.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fem: file(relativePath: { eq: "all-projects/all-fem.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hiroKids: file(relativePath: { eq: "all-projects/all-hiro-kids.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      krfty: file(relativePath: { eq: "all-projects/all-krfty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      micellar: file(relativePath: { eq: "all-projects/all-micellar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mighty: file(relativePath: { eq: "all-projects/all-mighty.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      monfina: file(relativePath: { eq: "all-projects/all-monfina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nuvo: file(relativePath: { eq: "all-projects/all-nuvo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s1mpl: file(relativePath: { eq: "all-projects/all-s1mpl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      studioTropik: file(
        relativePath: { eq: "all-projects/all-studio-tropik.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sweety: file(relativePath: { eq: "all-projects/all-sweety.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thisIsApril: file(
        relativePath: { eq: "all-projects/all-this-is-april.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vigo: file(relativePath: { eq: "all-projects/all-vigo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const portfolio = [
    {
      heading: "ALBENS CIDER",
      image: "albens",
      role: "Label Printing",
      client: "Albens",
      categories: "Food & Beverage",
    },
    {
      heading: "HIRO KIDS",
      image: "hiroKids",
      role: "Label Printing",
      client: "Hiro Kids",
      categories: "Food & Beverage",
    },
    {
      heading: "E+ BOTTLE",
      image: "eternalplus",
      role: "Label Printing",
      client: "Eternal Plus",
      categories: "Food & Beverage",
    },
    {
      heading: "VIGO",
      image: "vigo",
      role: "Label Printing",
      client: "Vigo",
      categories: "Food & Beverage",
    },
    {
      heading: "STUDIO TROPIK",
      image: "studioTropik",
      role: "Label Printing",
      client: "Albens",
      categories: "Pharmacy",
    },
    {
      heading: "CLING",
      image: "cling",
      role: "Label Printing",
      client: "Wings",
      categories: "Home & Garden",
    },
    {
      heading: "MICELLAR",
      image: "micellar",
      role: "Label Printing",
      client: "Micellar",
      categories: "Health & Beauty",
    },
    {
      heading: "ALOE VERA + CUCUMBER",
      image: "aloevera",
      role: "Label Printing",
      client: "Aloe Vera",
      categories: "Health & Beauty",
    },
    {
      heading: "MONFINA",
      image: "monfina",
      role: "Label Printing",
      client: "Monfina",
      categories: "Health & Beauty",
    },
    {
      heading: "ESENSES",
      image: "esenses",
      role: "Label Printing",
      client: "Esenses",
      categories: "Health & Beauty",
    },
    {
      heading: "NUVO",
      image: "nuvo",
      role: "Label Printing",
      client: "Nuvo",
      categories: "Health & Beauty",
    },
    {
      heading: "MIGHTY",
      image: "mighty",
      role: "Label Printing",
      client: "Mighty",
      categories: "Others",
    },
    {
      heading: "KRFTY",
      image: "krfty",
      role: "Label Printing",
      client: "Krfty",
      categories: "Others",
    },
    {
      heading: "S1MPL",
      image: "s1mpl",
      role: "Label Printing",
      client: "S1mpl",
      categories: "Others",
    },
    {
      heading: "SWEETY",
      image: "sweety",
      role: "Label Printing",
      client: "Sweety",
      categories: "Health & Beauty",
    },
    {
      heading: "THIS IS APRIL",
      image: "thisIsApril",
      role: "Label Printing",
      client: "This Is April",
      categories: "Others",
    },
  ]

  const openModal = modalName => {
    switch (modalName) {
      case "albens":
        setAlbensModal(true)
        break
      case "hiroKids":
        setHiroKidsModal(true)
        break
      case "eternalplus":
        setEternalplusModal(true)
        break
      case "vigo":
        setVigoModal(true)
        break
      case "studioTropik":
        setStudioTropikModal(true)
        break
      case "cling":
        setClingModal(true)
        break
      case "micellar":
        setMicellarModal(true)
        break
      case "aloevera":
        setAloeveraModal(true)
        break
      case "monfina":
        setMonfinaModal(true)
        break
      case "esenses":
        setEsensesModal(true)
        break
      case "nuvo":
        setNuvoModal(true)
        break
      case "mighty":
        setMightyModal(true)
        break
      case "krfty":
        setKrftyModal(true)
        break
      case "s1mpl":
        setS1mplModal(true)
        break
      case "sweety":
        setSweetyModal(true)
        break
      case "thisIsApril":
        setThisIsAprilModal(true)
        break
    }
  }

  const closeModal = modalName => {
    switch (modalName) {
      case "albens":
        setAlbensModal(false)
        break
      case "hiroKids":
        setHiroKidsModal(false)
        break
      case "eternalplus":
        setEternalplusModal(false)
        break
      case "vigo":
        setVigoModal(false)
        break
      case "studioTropik":
        setStudioTropikModal(false)
        break
      case "cling":
        setClingModal(false)
        break
      case "micellar":
        setMicellarModal(false)
        break
      case "aloevera":
        setAloeveraModal(false)
        break
      case "monfina":
        setMonfinaModal(false)
        break
      case "esenses":
        setEsensesModal(false)
        break
      case "nuvo":
        setNuvoModal(false)
        break
      case "mighty":
        setMightyModal(false)
        break
      case "krfty":
        setKrftyModal(false)
        break
      case "s1mpl":
        setS1mplModal(false)
        break
      case "sweety":
        setSweetyModal(false)
        break
      case "thisIsApril":
        setThisIsAprilModal(false)
        break
    }
  }

  return (
    <StyledPortfolioMobile>
      <div className="heading">
        <div className="line"></div>
        <div className="all-projects-wrapper">
          <div onClick={() => setVisible(!visible)}>ALL PROJECTS</div>
          <div
            ref={dropdownRef}
            className={`dropdown ${visible ? "" : "hide"}`}
          >
            {portfolio.map((item, i) => {
              return (
                <p
                  key={i}
                  onClick={() => {
                    openModal(item.image)
                  }}
                >
                  {item.heading}
                </p>
              )
            })}
          </div>
          <div className={`arrow-up ${visible ? "" : "hide"}`} />
        </div>
      </div>
      {portfolio.map((item, i) => {
        return (
          <Item
            item={item}
            data={data}
            {...portfolio[i]}
            key={i}
            openModal={openModal}
          />
        )
      })}
      {albensModal && <AlbensModal closeModal={closeModal} />}
      {hiroKidsModal && <HiroKidsModal closeModal={closeModal} />}
      {eternalplusModal && <EternalplusModal closeModal={closeModal} />}
      {vigoModal && <VigoModal closeModal={closeModal} />}
      {studioTropikModal && <StudioTropikModal closeModal={closeModal} />}
      {clingModal && <ClingModal closeModal={closeModal} />}
      {micellarModal && <MicellarModal closeModal={closeModal} />}
      {aloeveraModal && <AloeveraModal closeModal={closeModal} />}
      {monfinaModal && <MonfinaModal closeModal={closeModal} />}
      {esensesModal && <EsensesModal closeModal={closeModal} />}
      {nuvoModal && <NuvoModal closeModal={closeModal} />}
      {mightyModal && <MightyModal closeModal={closeModal} />}
      {krftyModal && <KrftyModal closeModal={closeModal} />}
      {s1mplModal && <S1mplModal closeModal={closeModal} />}
      {sweetyModal && <SweetyModal closeModal={closeModal} />}
      {thisIsAprilModal && <ThisIsAprilModal closeModal={closeModal} />}
    </StyledPortfolioMobile>
  )
}

export default PortfolioMobile
