import React, { useState, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import EmblaCarouselReact from "embla-carousel-react"
import Img from "gatsby-image"

import leftArrow from "../../../images/left-arrow.svg"
import rightArrow from "../../../images/right-arrow.svg"
import closeIcon from "../../../images/close-icon.svg"

const StyledEsensesModal = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  z-index: 12;
  .modal-wrapper {
    position: relative;
    .close {
      position: absolute;
      top: 1rem;
      right: 2rem;
      color: white;
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 10px;
      align-items: center;
      .close-icon {
        width: 20px;
        img {
          margin: 0;
          transform: translateY(5px);
        }
      }
    }
  }
  .gallery-wrapper {
    transform: translateY(100px);
    position: relative;
    img {
      margin: 0;
    }
    .pagination {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(-10px);
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 8px;
      .dot {
        height: 10px;
        width: 10px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid #4f4f4f;
        &.active {
          background-color: #4f4f4f;
        }
      }
    }
    .back-button {
      position: absolute;
      left: 10px;
      top: 50%;
      width: 20px;
      img {
        margin: 0;
      }
    }
    .next-button {
      position: absolute;
      right: 10px;
      top: 50%;
      width: 20px;
      img {
        margin: 0;
      }
    }
  }
`

const EsensesModal = props => {
  const [embla, setEmbla] = useState(null)
  const [selected, setSelected] = useState(0)
  const scrollTo = useCallback(
    i => {
      embla.scrollTo(i)
      setSelected(i)
    },
    [embla]
  )
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelected(embla.selectedScrollSnap())
      })
    }
  }, [embla])
  const data = useStaticQuery(graphql`
    query {
      full1: file(relativePath: { eq: "portfolio/esenses/full-size/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      full2: file(relativePath: { eq: "portfolio/esenses/full-size/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      full3: file(relativePath: { eq: "portfolio/esenses/full-size/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      full4: file(relativePath: { eq: "portfolio/esenses/full-size/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledEsensesModal>
      <div className="modal-wrapper">
        <div
          className="close"
          onClick={() => {
            props.closeModal("esenses")
          }}
        >
          <div>close</div>
          <div className="close-icon">
            <img src={closeIcon} alt="close icon" />
          </div>
        </div>
        <div className="gallery-wrapper">
          <EmblaCarouselReact
            emblaRef={setEmbla}
            htmlTagName="div"
            options={{ loop: false, align: "center", slidesToScroll: 1 }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: "0 0 100%" }}>
                <Img
                  fluid={data.full1.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
              <div style={{ flex: "0 0 100%" }}>
                <Img
                  fluid={data.full2.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
              <div style={{ flex: "0 0 100%" }}>
                <Img
                  fluid={data.full3.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
              <div style={{ flex: "0 0 100%" }}>
                <Img
                  fluid={data.full4.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
          </EmblaCarouselReact>
          <div className="pagination">
            <div
              className={`dot ${selected === 0 ? "active" : ""}`}
              onClick={() => scrollTo(0)}
            ></div>
            <div
              className={`dot ${selected === 1 ? "active" : ""}`}
              onClick={() => scrollTo(1)}
            ></div>
            <div
              className={`dot ${selected === 2 ? "active" : ""}`}
              onClick={() => scrollTo(2)}
            ></div>
            <div
              className={`dot ${selected === 3 ? "active" : ""}`}
              onClick={() => scrollTo(3)}
            ></div>
          </div>
          {selected !== 0 && (
            <div onClick={() => embla.scrollPrev()} className="back-button">
              <img src={leftArrow} alt="left arrow" />
            </div>
          )}
          {selected !== 3 && (
            <div onClick={() => embla.scrollNext()} className="next-button">
              <img src={rightArrow} alt="right arrow" />
            </div>
          )}
        </div>
      </div>
    </StyledEsensesModal>
  )
}

export default EsensesModal
